import {Container, Main, TextContainer} from "./Layout.styled";
import {useEffect, useState} from "react";
// import man from '../../assets/man.jpg'

// const data = [
// 	{
// 		id: 1,
// 		head: '0 ACCESSORIES',
// 		desc: ["Gloves", "Cap", "Chain", "Multitool"],
// 		top: 20,
// 		left: 225
// 	},
// 	{
// 		id: 2,
// 		head: '1 TOPS', desc: ["Denim Jacket", "Jersey", "Zip Hoodie", "T-shirt", "Body"],
// 		top: 280,
// 		left: 225
// 	},
// 	{
// 		id: 3,
// 		head: '2 BOTTOM', desc: ["Trek Pants"],
// 		top: 494,
// 		left: 225
// 	},
// 	{
// 		id: 4,
// 		head: '3 SHOES', desc: ["Pixelshoes", "Socks"],
// 		top: 740,
// 		left: 225
// 	},
// ]

const DateButton = () => {
	const [isShopNow, setIsShopNow] = useState(false);

	useEffect(() => {
		const targetDate = new Date("2024-12-01T00:00:00"); // Цільова дата

		// const interval = setInterval(() => {
			const currentDate = new Date();
			// console.log(targetDate, currentDate);
			if (currentDate >= targetDate) {
				setIsShopNow(true);
				// clearInterval(interval);
			}
		// }, 18000);

		// return () => clearInterval(interval);
	}, []);

	if (isShopNow) {
		return (
			<a href="https://alchevsque.com/collections/onlyforworkers" target="_blank" rel="noopener noreferrer">
				<button>Shop now</button>
			</a>
		);
	}

	return <button>Coming soon</button>;
};

export const Layout = () => {
	return (
		<Container>
			<Main>
				<TextContainer>
					<div className="">
						<p>ALCHEVSQUE™ (A123) × KOVALSKA
						ONLY FOR WORKERS</p>
						<p>EVENT - 29 NOV 2024 - 7 p.m</p>
						<p>ONLINE - 2 DEC 2024</p>
						{/*<p>FALL 24</p>*/}
						{/*<a href="https://www.youtube.com/watch?v=wRhxe0IcIEQ" target="_blank" rel="noopener noreferrer">20*/}
						{/*	SEP 2024 - EPISODE 01</a>*/}
						<DateButton/>
					</div>
					{/*<Form/>*/}
				</TextContainer>
				{/*<ImageContainer>*/}
				{/*	<img src={man} alt="who is"/>*/}
				{/*	{data.map(({id, head, desc, top, left}) => (*/}
				{/*		<Pin key={id} $top={top} $left={left}>*/}
				{/*			{head}*/}
				{/*			<ul>*/}
				{/*				{desc.map((i) =>*/}
				{/*					<li key={i}>{i}</li>*/}
				{/*				)}*/}
				{/*			</ul>*/}
				{/*		</Pin>*/}
				{/*	))}*/}
				{/*</ImageContainer>*/}
			</Main>
		</Container>
	);
}
