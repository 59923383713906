import styled from "styled-components";
import backgroundImage from "assets/back.jpeg";

export const Container = styled.div`
    position: relative;

    @media (max-width: 480px) {
        padding: 22px 10px;
		
        @media (max-height: 950px) {
            background-position: center calc(50% + (100vh - 95vh));
			overflow: hidden;
                &::before {
                    content: "";
                    position: absolute;
                    top: -40px;
                    left: -20px;
                    right: -20px;
                    height: 200px; 
                    background: inherit; 
                    filter: blur(14px);
                    z-index: -1; 
            }
        }

        @media (max-height: 820px) {
            &::before {
                top: -40px;
                right: -20px;
                height: 140px;
            }
        }

        @media (max-height: 730px) {
            background-position: center calc(50% + (100vh - 90vh));
        }

        @media (max-height: 600px) {
            &::before {
                top: -30px;
            }
            background-position: center calc(50% + (100vh - 85vh));
        }

        @media (max-height: 520px) {
            &::before {
                top: -30px;
                height: 160px;
            }
            background-position: center calc(50% + (100vh - 80vh));
        }
    }

    padding: 30px;
    color: white;
    min-height: 100vh;
    background: linear-gradient(
            112.33deg,
            rgba(0, 0, 0, 0.4) 0.13%,
            rgba(0, 0, 0, 0) 103.9%
    ),
    url(${backgroundImage}) center;
    background-size: cover;
    width: 100%;
    z-index: 0;
`;

export const Main = styled.main`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const TextContainer = styled.div`
    font-size: 15px;
    line-height: 1.1;
    margin: 0;
	
	div {
        max-width: 269px;
		margin: 0;
	}

    p {
        &:first-child {
            margin-bottom: 16px;
        }
    }

    a {
        //@media (max-width: 480px) {
        //    margin-top: 40px;
        //}
        //margin-top: 115px;
		//color: white;
		//display: block;
		cursor: pointer;
    }
	
	button {
		color: black;
		background-color: white;
		width: 263px;
		height: 37px;
		font-size: 15px;
		text-transform: uppercase;
		margin-top: 34px;
		cursor: inherit;
	}
`

export const ImageContainer = styled.div`
    @media (max-width: 1366px) {
        margin-left: 102px;
    }

    @media (max-width: 1133px) {
        margin-left: 13px;
    }

    @media (max-width: 768px) {
        margin-left: -24px;
        margin-top: 40px;
    }

    @media (max-width: 480px) {
        margin-top: 10px;
    }

    margin-top: 4px;
    position: relative;

    img {
        height: 834px;

        @media (max-width: 1133px) {
            height: 608px;
        }
    }


`

export const Pin = styled.span<{ $top: number, $left: number }>`

    position: absolute;
    top: ${({$top}) => $top}px;
    left: ${({$left}) => $left}px;
    width: max-content;
    min-width: 239px;
    padding-left: 125px;
    font-size: 15px;
    line-height: 1.1;

    @media (max-width: 1133px) {
        top: ${({$top}) => $top / 1.3717}px;
        left: ${({$left}) => $left / 1.3717}px;
    }

    @media (max-width: 480px) {
        min-width: 200px;
        padding-left: 65px;
    }

    &:before {
        content: "";
        display: block;
        background-color: white;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 19px;
        left: 0;
    }

    ul {
        margin-top: 16px;
    }
`
